<template>
  <div>
    <sidebar />
    <div class="main">
      <upbar title="" class="pb-5" /> <br />
      <b-alert v-model="showPullErrorAlert" variant="danger">{{
        pullErrorMessage
      }}</b-alert>

      <!-- ---------------------------------- Connectors State List ----------------------------------------->

     
      <div class="title">
        Connectors State
        <b-button class="btn bg-transparent btn-outline-light" @click="refresh">
          <b-icon icon="arrow-clockwise" variant="dark" scale="1"></b-icon>
        </b-button>

        <b-button class="btn bg-transparent btn-outline-light" @click="display_div = !display_div">
          <b-icon icon="caret-right" variant="dark" scale="1" v-if="display_div"></b-icon>
           <b-icon icon="caret-down" variant="dark" scale="1" v-if="!display_div"></b-icon>
        </b-button>
        
      </div>

      <br />
      <br />
      <div v-if="display_div" >
      <div class="bar row" >
        <div class="col-1"></div>
        <div class="col-4">
          <p class="tabTitle">Connector Name</p>
        </div>
        <div class="col-3">
          <p class="tabTitle">State</p>
        </div>
        <div class="col-4">
          <p class="tabTitle">Actions</p>
        </div>
      </div>
      <div v-if="endpointStates.length === 0" class="row">
        <p>
          <br />It looks like you don't have any source connectors yet. Please
          <a @click="goToConnectorsPage" class="link">click here</a> to create
          your first connector.
        </p>
      </div>
      <div v-for="con in endpointStates" :key="con.endpointId" class="bar1 row">
        <div class="col-1">
          <img :src="getLogoConnector(con.endpointType)" class="icon" />
        </div>
        <div class="col-4">
          <p class="tabText">{{ con.endpointName }}</p>
        </div>
        <div class="col-3">
          <p class="tabText">{{ con.statusId }}</p>
        </div>
        <div class="col-4">
          <div class="buttons">
            <b-button
              variant="outline-primary"
              class="botton-wide"
              @click="modalEndpointLogs(con.endpointId)"
              >Logs</b-button
            >
            <b-button
              variant="primary"
              class="botton-wide blue ml-3"
              v-on:click="repullConnector(con.endpointId)"
              >Pull Again</b-button
            >
          </div>
        </div>
      </div>
      </div>

      <!-- --------------------------------- Planner list ------------------------------ -->

      <div class="top">
        <b-alert v-model="showMigrateErrorAlert" variant="danger">{{
          migrateErrorMessage
        }}</b-alert>
        <div class="mt-4">
          <div class="title">
            Planner List
            <b-button
              class="btn bg-transparent btn-outline-light"
              @click="refresh"
            >
              <b-icon icon="arrow-clockwise" variant="dark" scale="1"></b-icon>
            </b-button>
          </div>
            <b-dropdown  class="plus-for-migrate mt-5"  text="Migration Actions">
            <b-dropdown-item @click="showMissingMappingsPopup">Start</b-dropdown-item>
            <b-dropdown-item @click="stopMigration"> Stop</b-dropdown-item>
          </b-dropdown>
           <div class="plus-white-trash mt-5" @click="deletePlans">
            <b-icon
              class="bicon-trash"
              variant="danger"
              icon="trash-fill"
              aria-hidden="true"
            >
            </b-icon>
            </div>
          <b-input-group class="plus-white mt-5">
            <b-input-group-prepend is-text>
              <b-icon class="altosio-icon" icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              size="lg"
              v-model="search"
              placeholder="Search..."
            ></b-form-input>
          </b-input-group>

          <div class="plus-entries mt-5">
            <div class="plusIn">
              {{tasks.length}} entries
            </div>
          </div>
        </div>
      </div>
      <br />
      <br /><br />

      <div class="bar row">
        <div class="col-1">
          <input type="checkbox" class="plan1" v-model="selectAll" />
        </div>
        <div class="col-1">
          <p class="tabTitle">
            <label for="vehicle1"> Status </label>
          </p>
        </div>
        <div class="col-1"></div>
        <div class="col-2">
          <p class="tabTitle">Source Name</p>
        </div>
        <div class="col-2">
          <p class="tabTitle">Parent</p>
        </div>
        <div class="col-1"></div>

        <div class="col-2">
          <p class="tabTitle">Target Name</p>
        </div>
        <div class="col-2">
          <p class="tabTitle">Actions</p>
        </div>
      </div>

      <div v-for="task in filteredList" :key="task.id" class="bar1 row">
        <div class="col-1">
          <input
            type="checkbox"
            class="plan pr-3"
            :value="task.id"
            v-model="selected"
          />
        </div>
        <div class="col-1"  @click="modalLogs(task.id)">
          <p class="tabText">
            <label for="vehicle1"> {{ task.statusId }}</label>
          </p>
        </div>
        <div class="col-1">
          <img :src="getLogoConnector(task.sourceEndpointType)" class="icon" />
        </div>
        <div class="col-2" @click="modalLogs(task.id)">
          <p class="tabText">
            {{ task.sourceName }}
          </p>
        </div>
        <div class="col-2">
          <p class="tabText">
            {{ task.sourceParent }}
          </p>
        </div>
        <div class="col-1">
          <img
            v-if="task.targetEndpointType != null"
            :src="getLogoConnector(task.targetEndpointType)"
            class="target-icon"
          />
          <b-icon
            v-else
            class="bicon-questionmark"
            variant="danger"
            icon="question"
            aria-hidden="true"
          />
        </div>
        <div class="col-2">
          <p class="tabText">{{ task.targetPlan }}</p>
        </div>
        <div class="col-2">
          <div class="buttons">
            <b-button
              variant="outline-primary"
              class="botton"
              v-on:click="modalLogs(task.id)"
              >Details</b-button
            >
            <b-icon
              class="bicon"
              variant="danger"
              icon="trash-fill"
              aria-hidden="true"
              @click="modalDeletePlan(task.id)"
            >
            </b-icon>
          </div>
        </div>
      </div>

      <b-modal size="xl" id="logs" hide-footer>
        <template #modal-title>
          <span class="modalTitle"> Planner Details</span>
        </template>
        <plannerListDetails
          @update-plan-details="updateDetails"
          :plannerId="planId"
        />
      </b-modal>
      <b-modal size="xl" title="Connector logs" id="endpoint-logs" hide-footer>
         <template #modal-title>
          <span class="modalTitle"> Connector logs </span>
        </template>
        <endpointLogs :endpointId="endpointId" />
      </b-modal>
      <b-modal size="xl" id="delete-plan" hide-footer>
        <deletePlanner :planId="planId" @delete-plan="finishPlannerDeletion" />
      </b-modal>

      <!-- --------------------------------- Start Migration Popup ------------------------------ -->

      <b-modal size="xl" id="popup" ref="popup" hide-footer>
        <div>
          <div class="modal-title">Select Migration Target</div>
          <div class="modal-form">
            <p class="modal-subtitle mt-3">Choose Target Connector</p>
            <b-form-select
              class="modal-shape col-9"
              v-model="selectedTarget"
              :options="Object.keys(targetsChoices)"
            ></b-form-select>
          </div>

          <div class="modal-button mb-5">
            <b-button
              variant="primary"
              class="modal-botton"
              v-on:click="updateTasks()"
              >Start Migration</b-button
            >
          </div>
        </div>
      </b-modal>
    <!-- --------------------------------- End Migration Popup ------------------------------ -->

    <!-- --------------------------------- Start User mappings Popup ------------------------------ -->

      <b-modal size="xl" id="mappingsWarning" ref="mappingsWarning" hide-footer>
          <div>
            <p class="modal-title">Empty Mappings</p>
            <p class="mapping-subtitle">
              You have one or more empty mappings in the Mapping Table section. Mappings are needed for the memberships and task assignment to be migrated.
              It is recommended to fill out all of the user mappings before running the migration. 
              Would you still want to proceed? 
            </p>
            <b-button variant="outline-danger" class="mappingButtonRed" v-on:click="startMigration()"
              >Yes</b-button
            >
            <b-button variant="primary"  class="mappingButtonBlue" v-on:click="hideUserMappingsWarning()">No, fill out the mappings</b-button>
          </div>
      </b-modal>
    <!-- --------------------------------- End Migration Popup ------------------------------ -->

    </div>
  </div>
</template>

<script>
import sidebar from "../components/sidebar.vue";
import upbar from "../components/upbar.vue";
import api from "../api/index";
import config from "../config/index";
import plannerListDetails from "../components/popup/plannerListDetails.vue";
import EndpointLogs from "../components/popup/endpointLogs.vue";
import deletePlanner from "../components/popup/deletePlanner.vue";

export default {
  name: "PlannerList",
  components: {
    sidebar,
    upbar,
    plannerListDetails,
    EndpointLogs,
    deletePlanner,
  },
  data() {
    return {
      search: "",
      tasks: [],
      logged: false,
      selected: [],
      targetEndpoints: [],
      selectAll: false,
      selectedTasks: [],
      targets: [],
      targetsChoices: {},
      needTargetTasks: [],
      selectedTarget: null,
      targetsTasksMapping: {},
      endpointStates: [],
      test: {},
      planId: null,
      endpointId: null,
      project: null,
      pullErrorMessage: "",
      showPullErrorAlert: false,
      showMigrateErrorAlert: false,
      display_div: true,
      migrateErrorMessage: "",
    };
  },
  computed: {
    filteredList() {
      return this.filteredListMethod();
    }
  },
  watch: {
    selected: function (taskIds) {
      this.needTargetTasks = [];
      this.selectedTasks = [];
      for (const taskId of taskIds) {
        const identifiedTask = this.tasks.find((x) => x.id === taskId);
        if (!identifiedTask.targetEndpointId) {
          this.needTargetTasks.push(identifiedTask.id);
        }
        this.selectedTasks.push(identifiedTask.id);
      }
    },

    selectAll: function (val) {
      this.toggleAll(val);
    },
  },
  created() {
    //this.getProjects();
  },
  mounted() {
    this.project = this.$store.getters.getProject;
    if (!this.project) {
      this.project = this.$route.params.id;
    }
    this.loadPlanner();
    this.getTargets(this.$route.params.id);
    this.loadEndpointStatus();
    this.timer = setInterval(() => {
      this.refresh();
    }, 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    filteredListMethod() {
      return this.tasks.filter((task) => {
        return JSON.stringify(task)
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    toggleDiv() {
    this.display_div = !this.display_div;
    },    
    goToConnectorsPage() {
      var projectId = this.$route.params.id;
      this.$router.push("/connectors/" + projectId);
    },
    goToMappingTablePage() {
      var projectId = this.$route.params.id;
      this.$router.push("/mapping/" + projectId);
    },
    finishPlannerDeletion(planId) {
      const identifiedTaskindex = this.tasks.findIndex((x) => x.id === planId);
      this.tasks.splice(identifiedTaskindex, 1);
    },
    updateDetails(planDetails) {
      const identifiedTask = this.tasks.find((x) => x.id === planDetails.id);
      identifiedTask.targetPlan = planDetails.targetPlanName;
    },
    toggleAll(checked) {
      var itemsWithNoTargetEndpoint = [];
      if(this.search==='')
      {
        //There was NO search query here
          this.selected = checked ? this.tasks.map((x) => x.id) : [];
        itemsWithNoTargetEndpoint= this.tasks.filter(
            (x) => x.targetEndpointId === null
          );
          this.needTargetTasks = itemsWithNoTargetEndpoint.map((x) => x.id);
      }
      else
      {
        //There was a Search query here
         var searchedTasks = this.filteredListMethod();
         this.selected = checked ? searchedTasks.map((x) => x.id) : [];
          itemsWithNoTargetEndpoint =  searchedTasks.filter(
            (x) => x.targetEndpointId === null
          );
          this.needTargetTasks = itemsWithNoTargetEndpoint.map((x) => x.id);
      }
      
    },
    showMissingMappingsPopup(){
      api.anyEmptyMappings(this.$route.params.id).then((response) => {
        var containsEmptyMappings = response.responseData;
        if(containsEmptyMappings){
            this.$refs["mappingsWarning"].show();
        }
        else{
          this.startMigration();
        }
      });
    },
    startMigration() {
      this.$refs["mappingsWarning"].hide();
      if (this.needTargetTasks.length > 0) {
        this.$refs["popup"].show();
      } else {
        this.updateTasks();
      }
    },
     stopMigration() {
      const projectId = this.$route.params.id;
        for (var j = 0; j < this.selectedTasks.length; j++) {
          var identifiedTask= this.tasks.find(
            (x) => x.id === this.selectedTasks[j]
          );
            const status = { statusId: 8 };
            api.changePlanStatus(projectId, this.selectedTasks[j], status);
            identifiedTask.statusId = "Stopping";
        }
        this.selected = [];
    },
    hideModal() {
      this.$refs["popup"].hide();
    },
    hideUserMappingsWarning(){
      this.goToMappingTablePage();
      this.$refs["mappingsWarning"].hide();
    },
    modalLogs(planId) {
      this.planId = planId;
      this.$bvModal.show("logs");
    },
    modalEndpointLogs(endpointId) {
      this.endpointId = endpointId;
      this.$bvModal.show("endpoint-logs");
    },
    modalDeletePlan(planId) {
      this.planId = planId;
      this.$bvModal.show("delete-plan");
    },
    deletePlans() {
      const projectId = this.$route.params.id;
      api.deletePlanners(projectId, this.selectedTasks);
      this.selectedTasks.forEach(element => {
        this.finishPlannerDeletion(element);
      });
      this.selected = [];
      
    },
    updateTasks() {
      const projectId = this.$route.params.id;
      var errorCounter = 0;
      //First, assign the target connector to the Planners that are missing a target connector *IF ANY*
      if (this.needTargetTasks.length > 0) {
        //1- Get the target Endpoint Id
        const IdentifiedTargetEndpoint = this.targetEndpoints.find(
          (x) => x.name === this.selectedTarget
        );
        // //2- Run the Update
        const dataToPost = {
          targetEndpointId: IdentifiedTargetEndpoint.id,
          objectsIds: this.needTargetTasks,
        };

        api.assignTargetEndpoint(projectId, dataToPost).then(() => {
          //Once the Target connector is assigned, schedule the migration.
          //3- Schedule the migration
          const status = { statusId: 7 };
          //3-A Check among the selected Tasks, which ones are already working or scheduled
          for (var i = 0; i < this.selectedTasks.length; i++) {
            var identifiedTask = this.tasks.find(
              (x) => x.id === this.selectedTasks[i]
            );
            if (
              identifiedTask.statusId === "Queued" ||
              identifiedTask.statusId === "Working"
            ) {
              errorCounter++;
              this.migrateErrorMessage =
                "Failed to start migration on " +
                errorCounter +
                " items. Items already scheduled or working.";
              this.showMigrateErrorAlert = true;
              setTimeout(() => {
                this.showMigrateErrorAlert = false;
              }, 2000);
            } else {
              api.changePlanStatus(projectId, this.selectedTasks[i], status);
              identifiedTask.statusId = "Queued";
              identifiedTask.targetEndpointType = IdentifiedTargetEndpoint.endpointType; //Planner
            }
          }
          this.selected = [];
        });
      } else {
        //Selected Plans ALL have a target connector assigned already
        for (var j = 0; j < this.selectedTasks.length; j++) {
          var identifiedTask = this.tasks.find(
            (x) => x.id === this.selectedTasks[j]
          );
          if (
            identifiedTask.statusId === "Queued" ||
            identifiedTask.statusId === "Working"
          ) {
            errorCounter++;
            this.migrateErrorMessage =
              "Failed to start migration on " +
              errorCounter +
              " items. Items already scheduled or working.";
            this.showMigrateErrorAlert = true;
            setTimeout(() => {
              this.showMigrateErrorAlert = false;
            }, 2000);
          } else {
            const status = { statusId: 7 };
            api.changePlanStatus(projectId, this.selectedTasks[j], status);
            identifiedTask.statusId = "Queued";
          }
        }
        this.selected = [];
      }

      this.$refs["popup"].hide();
    },
    refresh() {
      this.loadPlanner();
      this.loadEndpointStatus();
    },
    loadPlanner() {
      api.getPlannerOverview(this.$route.params.id).then((response) => {
        this.tasks = response.responseData;
        for (let item of this.tasks) {
          item.statusId = config.STATUS[item.statusId];
        }
      });
    },
    loadEndpointStatus() {
      api.getEndpointStatus(this.$route.params.id, 0).then((response) => {
        this.endpointStates = response.responseData;
        for (let item of this.endpointStates) {
          item.statusId = config.STATUS[item.statusId];
        }
      });
    },

    repullConnector(connectorId) {
      this.pullErrorMessage = "";
      const identifiedConnectorState = this.endpointStates.find(
        (x) => x.endpointId === connectorId
      );
      if (
        identifiedConnectorState.statusId === "Queued" ||
        identifiedConnectorState.statusId === "Working"
      ) {
        //Do not pull
        this.showPullErrorAlert = true;
        this.pullErrorMessage =
          "Cannot repull connector. Connector pull is already queued or working";
        setTimeout(() => {
          this.showPullErrorAlert = false;
        }, 2000);
      } else {
        this.pullErrorMessage = "";
        api.repullConnector(this.project, connectorId);
        identifiedConnectorState.statusId = "Queued";
      }
    },
    getTargets(id) {
      api.getEndpoints(id).then((response) => {
        this.targetEndpoints = response.responseData.filter(
          (connector) => connector.isTarget
        );
        this.targetsTasksMapping = {};
        for (let item of this.targetEndpoints) {
          this.targetsTasksMapping[item.id] = [];
          this.targetsChoices[item.name] = item.id;
        }
      });
    },
    getLogoConnector(id) {
      const EndpointImages = {
        0: "./planner.png",
        1: "./trello.png",
        2: "./asana.png",
        3: "./todoist.png",
        4: "./wrike.png",
        8: "./sharepoint.png",
        12: "./clickup.png",
        13: "./monday.png",
      };
      var images = require.context("../assets/", false, /\.png$/);
      return images(EndpointImages[id]);
    },
  },
};
</script>
<style scoped>
.main {
  margin-top: 53px;
  margin-left: 370px;
}
.bar {
  margin-top: 53px;
  margin-right: 40px;
  height: 53px;
  border-radius: 2px;
  background: #f2f6ff;
}
.bar1 {
  margin-right: 40px;
  height: 82px;
  border-radius: 2px;
  border-bottom: 2px solid #dfe0eb;
}
.tabTitle {
  margin-top: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  color: #2a4192;
}
.tabText {
  margin-top: 28px;
  font-size: 20px;
  line-height: 21px;
  color: #252733;
}
.bicon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  vertical-align: -10px;
  cursor: pointer;
}
.bicon-trash {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  margin-top: 8px;
  vertical-align: -10px;
}
.bicon-questionmark {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  vertical-align: -10px;
  margin-left: 50px;
  margin-top: 20px;
}
.altosio-icon {
  width: 21px;
  height: 36px;
}
.buttons {
  margin-top: 18px;
}
.botton {
  width: 70px;
  height: 44px;
  margin-right: 25px;
}
.botton-wide {
  width: 110px;
  height: 44px;
  margin-right: 25px;
}
.icon {
  margin-right: 20px;
  margin-top: 20px;
}
.target-icon {
  margin-right: 20px;
  margin-left: 50px;
  margin-top: 20px;
}
.plus {
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #2a4192;
  border-radius: 5px;
}
.plus-for-migrate {
  float: right;
  margin-right: 40px;
  font-style: normal;
  line-height: 30px;
  width: 180px;
  height: 50px;
  cursor: pointer;
  border-radius: 5px;
}
.plus-white {
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 5px;
}
.plus-white-trash{
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 30px;
  height: 10px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 5px;
}
.plus-entries {
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2a4192;;
  width: 190 px;
  height: 50px;
  cursor: pointer;
  background: #ffffff;
}
.link {
  color: #2a4192;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
}
.plusIn {
  margin-top: 10px;
  margin-left: 40px;
}
.iconPlus {
  margin-right: 20px;
}
.blue {
  background: #2a4192;
  border-radius: 5px;
}
.title {
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.3px;
  margin-top: 48px;
  color: #2a4192;
}
.top {
  margin-top: 60px;
}
.pang {
  position: absolute;
  right: 40px;
}
.pangText {
  position: absolute;
  float: left;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
}
/* ----------------------- */
.plan {
  width: 17px;
  height: 17px;
  background-color: gray;
  margin-right: 12px;
  margin-left: 5px;
  margin-top: 28px;
}
.plan1 {
  width: 20px;
  height: 20px;
  background-color: gray;
  margin-right: 9px;
  margin-top: 14px;
}
.modal-form {
  margin-left: 28%;
}
.modal-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.modal-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.modal-shape {
  width: 480px;
  height: 56px;
}
.modal-botton {
  margin-top: 30px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.modal-button {
  text-align: center;
}
.modalTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #23438e;
  margin-left: 400px;
  margin-right: auto;
}
.mappingButtonRed{
    margin-left: 35%;
    border: 2px solid #f03738;
    border-radius: 52px;
       font-size: 23px;
}
.mappingButtonBlue{
    margin-left: 40px;
    background: #232c68;
    border: 0.5px solid #c1c1c1;
    border-radius: 52px;
      font-size: 23px;
}
.mapping-subtitle{
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #232c68;
}
</style>
